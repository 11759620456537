<template>
  <div>
    <label>Запрашиваемая страница не найдена</label>
    <button @click="goToMain">На главную</button>
  </div>
</template>

<script>
export default {
  methods: {
    goToMain() {
      this.$router.replace('/office');
    },
  },
  computed: {
    displayAuth: {
      set(newValue) {
        if (newValue === true) this.goToMain();
      },
    },
  },
};
</script>

<style scoped>
label {
  color: white;
}

button {
  margin-top: 30px;
}

div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50vh;
}
</style>
