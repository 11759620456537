import { getUrl } from '../../index.js';
import { toISOLocal } from '../../index.js';

export default {
  async loadMap(context, payload) {
    let url = new URL(getUrl('map'));

    const params = {
      club_id: payload.id,
      datefrom: toISOLocal(payload.datefrom),
      dateto: toISOLocal(payload.dateto),
    };

    url.search = new URLSearchParams(params).toString();

    const response = await fetch(url);
    const responseData = await response.json();

    if (!response.ok) {
      // ...
    }

    const map = [];

    const data = responseData['result'];

    for (const key in data) {
      const dataItem = data[key];

      const device = {
        id: dataItem.id,
        number: dataItem.number,
        map_x: dataItem.x,
        map_y: dataItem.y,
        type: dataItem.device_type,
        is_available: dataItem.is_available,
        reserve_price: dataItem.reserve_price,
      };

      const zone = dataItem.zone;
      if (zone) device.zone_color = dataItem.zone.color;

      map.push(device);
    }

    context.commit('setMap', map);
  },
};
