import { getUrl } from '../../index.js';

export default {
  async login(context, payload) {
    const formData = new FormData();
    formData.append('username', payload.username);
    formData.append('password', payload.password);
    formData.append('club_id', payload.clubId);

    const response = await fetch(getUrl('login'), {
      method: 'POST',
      body: formData,
    });

    const responseData = await response.json();

    if (!response.ok) {
      console.log(responseData);
      const error = new Error(responseData.error || 'Failed to authenticate.');
      throw error;
    }

    localStorage.setItem('token', responseData.token);

    context.commit('setUser', {
      token: responseData.token,
    });
  },
  async signUp(context, payload) {
    const formData = new FormData();
    formData.append('username', payload.username);
    formData.append('email', payload.email);
    formData.append('password', payload.password);

    const response = await fetch(getUrl('sign_up'), {
      method: 'POST',
      body: formData,
    });

    const responseData = await response.json();

    if (!response.ok) {
      console.log(responseData);
      const error = new Error(responseData.error || 'Failed to sign up.');
      throw error;
    }

    return responseData.result;
  },
  async signUpConfirm(context, payload) {
    const formData = new FormData();
    formData.append('id', payload.id);
    formData.append('code', payload.code);

    const response = await fetch(getUrl('sign_up_confirm'), {
      method: 'POST',
      body: formData,
    });

    const responseData = await response.json();

    if (!response.ok) {
      console.log(responseData);
      const error = new Error(
        responseData.error || 'Failed to confirm sign up.'
      );
      throw error;
    }

    localStorage.setItem('token', responseData.token);

    context.commit('setUser', {
      token: responseData.token,
    });
  },
  loadStoredToken(context) {
    const token = localStorage.getItem('token');
    if (token) {
      context.commit('setUser', {
        token: token,
      });
    }
  },
  async signOut(context) {
    const formData = new FormData();
    formData.append('token', context.rootState.auth.token);

    const response = await fetch(getUrl('logout'), {
      method: 'POST',
      body: formData,
    });

    const responseData = await response.json();

    if (!response.ok && response.status != 401) {
      console.log(responseData);
      const error = new Error(responseData.error || 'Failed to logout.');
      throw error;
    }

    this.dispatch('auth/removeToken');
  },
  removeToken(context) {
    localStorage.removeItem('token');

    context.commit('setUser', {
      token: null,
    });
  },
  async getProfile(context) {
    const formData = new FormData();
    formData.append('token', context.rootState.auth.token);

    const response = await fetch(getUrl('user_info'), {
      method: 'POST',
      body: formData,
    });

    const responseData = await response.json();

    if (!response.ok) {
      console.log(responseData);

      if (response.status == 401) {
        throw '401';
      }

      const error = new Error(
        responseData.error || 'Failed to load profile info.'
      );
      throw error;
    }

    context.commit('setBalance', {
      balance: responseData.balance,
    });
  },
  async restorePasswordSendEmail(context, payload) {
    const formData = new FormData();
    formData.append('username', payload.email);

    const response = await fetch(getUrl('restore_password_send_email'), {
      method: 'POST',
      body: formData,
    });

    const responseData = await response.json();

    if (!response.ok) {
      console.log(responseData);
      const error = new Error(
        responseData.error || 'Failed to request restore email.'
      );
      throw error;
    }
  },
  async restorePassword(context, payload) {
    const formData = new FormData();
    formData.append('username', payload.email);
    formData.append('code', payload.code);
    formData.append('new_password', payload.newPassword);

    const response = await fetch(getUrl('restore_password'), {
      method: 'POST',
      body: formData,
    });

    const responseData = await response.json();

    if (!response.ok) {
      console.log(responseData);
      const error = new Error(responseData.error || 'Failed to verify code.');
      throw error;
    }

    return responseData;
  },
};
