import { createRouter, createWebHistory } from 'vue-router';

import Main from './pages/office/MainPage.vue';
import MyReservations from './pages/reservations/MyReservations.vue';
import NotFound from './pages/NotFound.vue';

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: '/', redirect: '/office' },
    { path: '/office', component: Main },
    { path: '/office/:id', component: null },
    { path: '/register', component: null },
    { path: '/reservations', component: MyReservations },
    { path: '/:notFound(.*)', component: NotFound },
  ],
});

export default router;
