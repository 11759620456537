<template>
  <ul :style="gridStyle" class="card-list">
    <component
      :is="getCardComponent(card)"
      v-for="(card, index) in cards"
      :key="index"
      class="card-item"
      :device="card"
    />
  </ul>
</template>

<script>
import Device from './../../components/map/DeviceItem.vue';
import Empty from './../../components/map/EmptyItem.vue';

export default {
  components: {
    Device,
    Empty,
  },
  data() {
    return {
      cards: [],
      numberOfColumns: 16,
    };
  },
  computed: {
    gridStyle() {
      const vw = Math.max(
        document.documentElement.clientWidth || 0,
        window.innerWidth || 0
      );
      const width = vw >= 768 ? '40px' : '1fr';
      return {
        gridTemplateColumns: `repeat(${this.numberOfColumns}, ${width})`,
      };
    },
    clubs() {
      return this.$store.getters['club/clubs'];
    },
    map() {
      return this.$store.getters['map/map'];
    },
  },
  methods: {
    async loadMap(id, datefrom, dateto) {
      await this.$store.dispatch('map/loadMap', { id, datefrom, dateto });
      this.cards = [];
      const columns = this.numberOfColumns;
      const maxRows = 12;
      let rows = 0;

      this.map.forEach((device) => {
        const row = device.map_y + 1;
        if (row > rows && row <= maxRows) rows = row;
      });

      for (let i = 0; i < columns * rows; i++) {
        this.cards.push(i);
      }

      this.map.forEach((device) => {
        const position = device.map_x + device.map_y * columns;
        if (position < this.cards.length) this.cards[position] = device;
      });
    },
    getCardComponent(card) {
      return typeof card === 'number' ? 'empty' : 'device';
    },
  },
};
</script>

<style scoped>
.card-list {
  display: grid;
  gap: 4px 3px;
}

.card-item {
  width: 20px;
  height: 40px;
}

ul {
  list-style-type: none;
  padding-inline-start: 0px;
}

@media only screen and (min-width: 768px) {
  /* For desktop: */
  .card-list {
    gap: 10px 4px;
  }

  .card-item {
    width: 40px;
    height: 58px;
  }
}
</style>
