import { getUrl } from '../../index.js';

export default {
  async loadClubs(context) {
    const response = await fetch(getUrl('clubs'));
    const data = await response.json();

    if (!response.ok) {
      const error = new Error(data.message || 'Failed to fetch clubs.');
      throw error;
    }

    const clubs = [];

    for (const key in data) {
      const club = {
        id: data[key].id,
        name: data[key].name,
        online_payment_available: data[key].online_payment_available,
        reserve_max_devices_for_user: data[key].reserve_max_devices_for_user,
      };

      clubs.push(club);
    }

    context.commit('setClubs', clubs);
  },
  async loadZones(context, clubId) {
    var params = { club_id: clubId };

    let url = new URL(getUrl('zones'));
    url.search = new URLSearchParams(params).toString();

    const response = await fetch(url);
    const responseData = await response.json();

    if (!response.ok) {
      const error = new Error(responseData.message || 'Failed to fetch zones.');
      throw error;
    }

    const zones = [];

    for (const key in responseData) {
      const zone = {
        id: responseData[key].id,
        name: responseData[key].name,
        color: responseData[key].color,
      };

      zones.push(zone);
    }

    context.commit('setZones', zones);
  },
};
