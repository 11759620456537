<template>
  <table>
    <thead>
      <tr>
        <th>Клуб</th>
        <th>Дата и время</th>
        <th>Сеанс</th>
        <th>Зона</th>
        <th>№ ПК/Консоли</th>
        <th>Код</th>
        <th>Депозит, руб</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="row in reservations" :key="row.id">
        <td>{{ row.club }}</td>
        <td>{{ row.datetime.toLocaleString() }}</td>
        <td>{{ row.duration }}</td>
        <td>{{ row.zone }}</td>
        <td>{{ row.device_number }}</td>
        <td>{{ row.code }}</td>
        <td>{{ row.deposit }}</td>
        <td><button @click="cancel(row.id)">Отмена</button></td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: {
    reservations: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  methods: {
    async cancel(id) {
      await this.$store.dispatch('reservations/cancel', id);
      await this.$store.dispatch('reservations/loadMy', id);
    },
  },
};
</script>

<style scoped>
table {
  width: 100%;
  margin: 0 auto;
  background: #171a1e;
  color: grey;
  box-shadow: 0 4px 24px rgb(0 0 0 / 45%);
}

th {
  text-align: center;
  padding: 10px 0;
}

tr {
  justify-content: space-around;
}

td {
  flex-basis: 14%;
  padding: 8px 0px;
  text-align: center;
  color: white;
  background: #232529;
}

table {
  border-collapse: collapse;
}

@media only screen and (max-width: 760px),
  (min-device-width: 768px) and (max-device-width: 1024px) {
  table,
  thead,
  tbody,
  th,
  td,
  tr {
    display: block;
  }

  thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  tr:not(:last-child) {
    border-bottom: 1px solid #eee;
  }

  td {
    /* Behave  like a "row" */
    border: none;
    position: relative;
    padding-left: 50%;
  }

  td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 6px;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
  }

  td:nth-of-type(1):before {
    content: 'Клуб';
  }
  td:nth-of-type(2):before {
    content: 'Дата и время';
  }
  td:nth-of-type(3):before {
    content: 'Сеанс';
  }
  td:nth-of-type(4):before {
    content: 'Зона';
  }
  td:nth-of-type(5):before {
    content: '№ ПК/Консоли';
  }
  td:nth-of-type(6):before {
    content: 'Код';
  }
  td:nth-of-type(7):before {
    content: 'Депозит, руб';
  }
}
</style>
