export default {
  setClubs(state, payload) {
    state.clubs.splice(0, state.clubs.length);
    payload.forEach((club) => {
      state.clubs.push(club);
    });
  },
  setZones(state, payload) {
    state.zones.splice(0, state.zones.length);
    payload.forEach((zone) => {
      state.zones.push(zone);
    });
  },
};
