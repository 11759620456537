<template>
  <div id="maindiv" @click="close"></div>
  <dialog open>
    <img class="close" src="./../../assets/close.png" @click="close" />
    <div id="contentdiv">
      <reserv-card :devices="devices" :period="period" :date="dateFrom" />
      <div
        style="display: inline-flex; padding: 5px"
        v-for="device in devices"
        :key="device.id"
      >
        <device :device="device"></device>
      </div>
      <p>Баланс: {{ balance }} руб</p>
      <input
        v-if="isPaymentAmountVisible"
        v-model="paymentAmount"
        type="number"
        min="0"
        placeholder="Введите сумму пополнения"
      />
      <button
        v-if="this.club.online_payment_available && !createdReserve"
        @click="makePayment"
      >
        Пополнить
      </button>
      <button id="reserveBtn" v-if="!createdReserve" @click="makeReserv">
        С баланса
      </button>
      <p class="error" v-if="error">{{ error }}</p>
      <p class="success" v-if="createdReserve">
        Резерв успешно создан! Код: {{ reserveCode }}
      </p>
    </div>
  </dialog>
</template>

<script>
import ReservCard from './../../components/layout/ReservCard.vue';
import Device from './../../components/map/DeviceItem.vue';
export default {
  data() {
    return {
      error: null,
      createdReserve: false,
      reserveCode: null,
      isPaymentAmountVisible: false,
      paymentAmount: '',
    };
  },
  emits: ['close'],
  components: {
    ReservCard,
    Device,
  },
  props: {
    devices: null,
    club: null,
    period: null,
    dateFrom: null,
  },
  methods: {
    async makeReserv() {
      this.error = null;

      try {
        const reserve = await this.$store.dispatch('reservations/create', {
          date: this.dateFrom,
          clubId: this.club.id,
          deviceIds: this.devices.map((device) => device.id),
          durationMin: this.period.minutes,
        });

        this.reserveCode = reserve.code;
        console.log(this.reserveCode);
      } catch (err) {
        this.error = err.message || 'Failed to create reservation.';
        return;
      }

      this.createdReserve = true;
    },
    async makePayment() {
      if (!this.isPaymentAmountVisible) {
        this.isPaymentAmountVisible = true;
        return;
      }

      this.error = null;

      try {
        const paymentUrl = await this.$store.dispatch('user/payment', {
          clubId: this.club.id,
          amount: +this.paymentAmount,
        });

        if (!paymentUrl) {
          this.error = "Coudn't get payment url";
        }

        window.location.href = paymentUrl;
      } catch (err) {
        this.error = err.message;
        return;
      }
    },
    close() {
      this.$emit('close');
    },
  },
  computed: {
    balance() {
      return this.$store.getters['auth/balance'];
    },
  },
};
</script>

<style scoped>
#maindiv {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 10;
}
#contentdiv {
  margin-top: 40px;
  flex-direction: row;
}
div {
  display: block;
  text-align: center;
  padding: 0;
}

dialog {
  position: fixed;
  top: 30vh;
  width: 80%;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  z-index: 100;
  border: none;
  padding: 0;
  overflow: hidden;
  background-color: #232529;
}
img.close {
  width: 16px;
  height: 16px;
  cursor: pointer;
  padding: 16px;
  float: right;
}

button {
  margin: 10px auto;
  width: 40%;
  display: block;
}

#reserveBtn {
  margin-bottom: 15px;
}

p {
  color: white;
}

p.error {
  color: brown;
}

p.success {
  color: lightgreen;
}

input {
  width: 38%;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
</style>
