<template>
  <svg
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="350.474px"
    height="350.474px"
    viewBox="0 0 350.474 350.474"
    style="enable-background: new 0 0 350.474 350.474"
    xml:space="preserve"
  >
    <g>
      <path
        d="M350.459,149.628c0-62.413-53.082-122.777-151.563-90.406c-7.473,2.456-39.486,2.2-47.936-0.431
C55.156,28.967,0.015,87.922,0.015,149.628c0,80.014-1.636,150.656,29.731,150.656c25.623,0,46.577-48.464,92.173-94.062
c15.429-15.429,91.209-15.429,106.637,0c55.588,55.588,61.979,94.062,92.174,94.062
C352.096,300.284,350.459,232.09,350.459,149.628z M72.783,95.062c8.272,0,14.979,6.706,14.979,14.979s-6.706,14.979-14.979,14.979
s-14.979-6.706-14.979-14.979S64.511,95.062,72.783,95.062z M45.505,152.298c-8.272,0-14.979-6.706-14.979-14.979
s6.706-14.979,14.979-14.979s14.979,6.706,14.979,14.979S53.778,152.298,45.505,152.298z M72.783,179.576
c-8.272,0-14.979-6.706-14.979-14.979s6.706-14.979,14.979-14.979s14.979,6.706,14.979,14.979S81.056,179.576,72.783,179.576z
 M100.062,152.298c-8.272,0-14.979-6.706-14.979-14.979s6.707-14.979,14.979-14.979s14.979,6.706,14.979,14.979
S108.334,152.298,100.062,152.298z M186.736,141.53h-28.386c-4.362,0-7.898-3.536-7.898-7.898s3.537-7.898,7.898-7.898h28.386
c4.361,0,7.898,3.536,7.898,7.898S191.098,141.53,186.736,141.53z M317.066,144.282c0,3.3-2.699,6-6,6h-13.926
c-3.299,0-6,0.909-6,2.019s0,4.719,0,8.02v9.888c0,3.3-2.699,6-6,6h-13.927c-3.299,0-6-2.7-6-6v-9.888c0-3.301,0-6.909,0-8.02
c0-1.109-2.698-2.019-6-2.019h-13.926c-3.299,0-6-2.7-6-6v-13.926c0-3.3,2.701-6,6-6h13.926c3.302,0,6-2.7,6-6v-13.925
c0-3.301,2.701-6,6-6h13.927c3.301,0,6,2.699,6,6v13.925c0,3.3,2.701,6,6,6h13.926c3.301,0,6,2.7,6,6V144.282z"
      />
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>
