<template>
  <the-header @gotoAuth="gotoAuth"></the-header>
  <router-view v-slot="{ Component }">
    <component ref="view" :is="Component" />
  </router-view>
</template>

<script>
import TheHeader from './components/layout/TheHeader.vue';

export default {
  methods: {
    gotoAuth() {
      this.$refs.view.displayAuth = true;
    },
  },
  components: {
    TheHeader,
  },
  created() {
    this.$store.dispatch('auth/loadStoredToken');
  },
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;450&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.1/css/all.min.css');

html {
  font-family: 'Montserrat', sans-serif;
  height: 100%;
}

body {
  margin: 0;
  height: 100%;
  background-color: #232529;
}

#app {
  height: 100%;
  display: flex;
  flex-direction: column;
}
</style>
