<template>
  <div class="container">
    <div class="dropdown" ref="dropdown">
      <div class="select">
        <span class="choose">{{ title }}</span>
        <i class="fa fa-chevron-left"></i>
      </div>
      <input type="hidden" name="gender" />
      <ul class="dropdown-menu">
        <li
          v-for="item in items"
          :key="item.id"
          :id="item.id"
          @click="itemClick($event)"
        >
          {{ item.name }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import $ from 'jquery';

export default {
  props: ['items', 'title'],
  emits: ['item-selected'],
  methods: {
    itemClick(event) {
      const li = event.target;
      $(this.$refs.dropdown).find('span').text($(li).text());
      $(this.$refs.dropdown).find('input').attr('value', $(li).attr('id'));

      const itemId = $(li).attr('id');
      const item = this.items.find((item) => item.id.toString() === itemId);
      this.$emit('item-selected', item);
    },
    setText(text) {
      $(this.$refs.dropdown).find('span').text(text);
    },
  },
  mounted() {
    /*Dropdown Menu*/
    this.$refs.dropdown.addEventListener('click', function () {
      $(this).attr('tabindex', 1).focus();
      $(this).toggleClass('active');
      $(this).find('.dropdown-menu').slideToggle(300);
    });
    this.$refs.dropdown.addEventListener('focusout', function () {
      $(this).removeClass('active');
      $(this).find('.dropdown-menu').slideUp(300);
    });
    $('.dropdown .dropdown-menu li').click(function () {
      $(this).parents('.dropdown').find('span').text($(this).text());
      $(this)
        .parents('.dropdown')
        .find('input')
        .attr('value', $(this).attr('id'));
    });
    /*End Dropdown Menu*/
  },
};
</script>

<style scoped>
* {
  outline: 0;
}

span.choose {
  color: white;
}

/*Styling Selectbox*/
.dropdown {
  border-radius: 2px;
  box-shadow: 0 0 2px rgb(204, 204, 204);
  transition: all 0.5s ease;
  position: relative;
  font-size: 14px;
  color: #474747;
  height: 100%;
  text-align: left;
}
.dropdown .select {
  cursor: pointer;
  display: block;
  padding: 10px;
}
.dropdown .select > i {
  font-size: 13px;
  color: #888;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  float: right;
  line-height: 20px;
}
.dropdown:hover {
  box-shadow: 0 0 4px rgb(204, 204, 204);
}
.dropdown:active {
  background-color: #f8f8f8;
}
.dropdown.active:hover,
.dropdown.active {
  box-shadow: 0 0 4px rgb(204, 204, 204);
  border-radius: 2px 2px 0 0;
}
.dropdown.active .select > i {
  transform: rotate(-90deg);
}
.dropdown .dropdown-menu {
  position: absolute;
  background-color: #fff;
  width: 100%;
  left: 0;
  margin-top: 1px;
  box-shadow: 0 1px 2px rgb(204, 204, 204);
  border-radius: 0 1px 2px 2px;
  overflow: hidden;
  display: none;
  max-height: 144px;
  overflow-y: auto;
  z-index: 9;
}
.dropdown .dropdown-menu li {
  padding: 10px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.dropdown .dropdown-menu {
  padding: 0;
  list-style: none;
}
.dropdown .dropdown-menu li:hover {
  background-color: #f2f2f2;
}
</style>
