<template>
  <form @submit.prevent="submitForm">
    <label v-if="codeStage"
      >Введите код подтверждения, отправленный на email</label
    >
    <input v-if="!codeStage" v-model="username" placeholder="Логин" />
    <input v-if="!codeStage" v-model="email" type="email" placeholder="Email" />
    <PasswordEyed
      v-if="!codeStage"
      class="passwordEyed"
      v-model.trim="password"
    />
    <input
      v-if="codeStage"
      v-model="code"
      type="number"
      placeholder="Код подтверждения"
    />
    <p class="error" v-if="error">{{ error }}</p>
    <p v-if="!formIsValid" class="error">Пожалуйста, заполните все поля</p>
    <button>Дальше</button>
  </form>
</template>

<script>
import PasswordEyed from './../../components/PasswordEyed.vue';

export default {
  emits: ['authenticated'],
  components: {
    PasswordEyed,
  },
  data() {
    return {
      username: '',
      email: '',
      password: '',
      userId: null,
      code: '',
      codeStage: false,
      formIsValid: true,
      isLoading: false,
      error: null,
    };
  },
  methods: {
    async submitForm() {
      this.error = null;
      this.formIsValid = true;

      if (
        !this.codeStage &&
        (this.username === '' || this.email === '' || this.password === '')
      ) {
        this.formIsValid = false;
        return;
      }

      if (this.codeStage && this.code === '') {
        this.formIsValid = false;
        return;
      }

      this.isLoading = true;

      let result = null;

      if (this.codeStage) {
        await this.signUpConfirm();
      } else {
        result = await this.signUp();
      }

      this.userId = result.id;

      this.isLoading = false;

      if (!this.error) this.codeStage = true;
    },
    async signUp() {
      try {
        const result = await this.$store.dispatch('auth/signUp', {
          username: this.username,
          email: this.email,
          password: this.password,
        });

        return result;
      } catch (err) {
        this.error = err.message || 'Failed to sign up, try later.';
      }
    },
    async signUpConfirm() {
      try {
        await this.$store.dispatch('auth/signUpConfirm', {
          id: this.userId,
          code: this.code,
        });

        this.$emit('authenticated');
      } catch (err) {
        this.error = err.message || 'Failed to verify code, try later.';
      }
    },
  },
};
</script>

<style scoped>
form {
  display: flex;
  flex-direction: column;
  align-items: center;
}
p,
label {
  color: white;
}
p.error {
  color: brown;
}
label {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 20px;
}
.passwordEyed,
input,
button {
  margin-top: 10px;
  margin-bottom: 10px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
</style>
