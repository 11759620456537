<template>
  <header>
    <nav>
      <h1><router-link to="/">OASys</router-link></h1>
      <ul>
        <li v-if="isLoggedIn">
          <router-link to="/reservations">Мои резервы</router-link>
        </li>
        <li v-else>
          <a @click="this.$emit('gotoAuth')" to="/">Войти</a>
        </li>
        <li v-if="isLoggedIn">
          <a @click="signOut" to="/">Выйти</a>
        </li>
      </ul>
    </nav>
  </header>
</template>

<script>
export default {
  emits: ['gotoAuth'],
  computed: {
    isLoggedIn() {
      return this.$store.getters['auth/isAuthenticated'];
    },
  },
  methods: {
    async signOut() {
      await this.$store.dispatch('auth/signOut');
      this.$router.go();
    },
  },
};
</script>

<style scoped>
header {
  width: 100%;
  height: 5rem;
  background-color: #171a1e;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 24px rgba(0, 0, 0, 0.74);
}

header a {
  text-decoration: none;
  color: white;
  display: inline-block;
  padding: 0.75rem 1.5rem;
  border: 1px solid transparent;
  cursor: pointer;
}

a:active,
a:hover,
a.router-link-active {
  border: 1px solid #75b4ff;
}

h1 {
  margin: 0;
}

h1 a {
  color: white;
  margin: 0;
}

h1 a:hover,
h1 a:active,
h1 a.router-link-active {
  border-color: transparent;
}

header nav {
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

header ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

li {
  margin: 0 0.5rem;
}
</style>
