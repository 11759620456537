import { getUrl } from '../../index.js';

export default {
  async payment(context, payload) {
    const formData = new FormData();
    formData.append('token', context.rootState.auth.token);
    formData.append('amount', payload.amount);
    formData.append('club_id', payload.clubId);

    const response = await fetch(getUrl('payment'), {
      method: 'POST',
      body: formData,
    });

    const responseData = await response.json();

    if (!response.ok) {
      console.log(responseData);
      const error = new Error(
        responseData.error || 'Failed to make payment request.'
      );
      throw error;
    }

    return responseData.result.url;
  },
};
