<template>
  <form @submit.prevent="submitForm">
    <input type="text" v-model.trim="username" placeholder="Логин" />
    <PasswordEyed class="passwordEyed" v-model.trim="password" />
    <p class="error" v-if="error">{{ error }}</p>
    <p v-if="!formIsValid" class="error">
      Пожалуйста, введите корректные логин/пароль
    </p>
    <button>Войти</button>
  </form>
</template>

<script>
import PasswordEyed from './../../components/PasswordEyed.vue';

export default {
  emits: ['authenticated'],
  components: {
    PasswordEyed,
  },
  data() {
    return {
      username: '',
      password: '',
      formIsValid: true,
      isLoading: false,
      error: null,
    };
  },
  methods: {
    async submitForm() {
      this.error = null;
      this.formIsValid = true;

      if (this.username === '' || this.password === '') {
        this.formIsValid = false;
        return;
      }

      this.isLoading = true;

      const clubId = localStorage.getItem('selected_club_id');

      try {
        await this.$store.dispatch('auth/login', {
          username: this.username,
          password: this.password,
          clubId: clubId,
        });

        await this.$store.dispatch('auth/getProfile');
      } catch (err) {
        this.error = err.message || 'Failed to authenticate, try later.';
        return;
      }

      this.isLoading = false;
      this.$emit('authenticated');
    },
  },
};
</script>

<style scoped>
form {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.passwordEyed,
input,
button {
  margin-top: 10px;
  margin-bottom: 10px;
}
p {
  color: white;
}
p.error {
  color: brown;
}
</style>
