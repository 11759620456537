<template>
  <div style="position: relative">
    <input
      ref="passwordInput"
      type="password"
      v-model="password"
      @input="handleInput"
      placeholder="Пароль"
    />
    <i
      id="passwordEye"
      ref="passwordEye"
      class="far fa-eye"
      @click="togglePassword"
    ></i>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: String,
  },
  emits: ['update:modelValue'],
  data() {
    return {
      password: '',
    };
  },
  methods: {
    togglePassword() {
      const type =
        this.$refs.passwordInput.getAttribute('type') === 'password'
          ? 'text'
          : 'password';
      this.$refs.passwordInput.setAttribute('type', type);
      this.$refs.passwordEye.classList.toggle('fa-eye-slash');
      this.$refs.passwordEye.classList.toggle('fa-eye');
    },
    handleInput() {
      this.$emit('update:modelValue', this.password);
    },
  },
};
</script>

<style scoped>
#passwordEye {
  position: absolute;
  cursor: pointer;
  color: lightgray;
  top: 9px;
  margin-left: -30px;
  font-size: 14px;
}
</style>
