<template>
  <div class="row" id="main_container">
    <sidebar
      class="col-3"
      ref="sidebar"
      id="sidebar"
      @dateChanged="onDateChange"
    ></sidebar>
    <div class="col-9 main">
      <h3 v-if="selectedClub">{{ selectedClub.name }}</h3>
      <device-map ref="deviceMap" />
    </div>
    <div id="loaderdiv" v-if="isLoading">
      <img src="./../../assets/loading.gif" id="loaderimg" />
    </div>
    <button
      id="makeReserveButton"
      v-if="selectedDevices.length"
      type="button"
      @click="onMakeReserveButtonClicked"
    >
      Зарезервировать
    </button>
    <login-dialog
      v-if="displayAuth"
      @close="this.displayAuth = false"
      @restorePassword="restorePasswordHandler"
    />
    <reserve-dialog
      v-if="displayReserve"
      ref="reserveDialog"
      @close="onReserveDialogClosed"
      :devices="selectedDevices"
      :period="selectedPeriod"
      :club="selectedClub"
      :dateFrom="dateFrom"
    />
    <restore-dialog
      v-if="displayRestore"
      @close="this.displayRestore = false"
    />
  </div>
</template>

<script>
import Sidebar from './LeftSidebar.vue';
import DeviceMap from './DeviceMap.vue';
import ReserveDialog from './ReserveDialog.vue';
import LoginDialog from './../login/LoginDialog.vue';
import RestoreDialog from '../restore/RestoreDialog.vue';

export default {
  components: {
    Sidebar,
    DeviceMap,
    ReserveDialog,
    LoginDialog,
    RestoreDialog,
  },
  data() {
    return {
      selectedClub: null,
      selectedDevices: [],
      selectedPeriod: null,
      displayAuth: false,
      displayRestore: false,
      displayReserve: false,
      isLoading: false,
      hour: null,
      minute: null,
      date: null,
    };
  },
  computed: {
    gridStyle() {
      return {
        gridTemplateColumns: `repeat(${this.numberOfColumns}, 40px)`,
      };
    },
    clubs() {
      return this.$store.getters['club/clubs'];
    },
    map() {
      return this.$store.getters['map/map'];
    },
    isLoggedIn() {
      return this.$store.getters['auth/isAuthenticated'];
    },
    periods() {
      return this.$store.getters['reservations/periods'];
    },
    dateFrom() {
      const timestamp = Date.parse(this.date);
      let dateFrom = new Date(timestamp);
      dateFrom.setHours(this.hour);
      dateFrom.setMinutes(this.minute);
      return dateFrom;
    },
  },
  watch: {
    isLoggedIn(newValue) {
      if (newValue === false) this.selectedDevices = [];
    },
  },
  created() {
    console.log('Version: 1.7.0');
    this.loadData();
  },
  mounted() {},
  provide() {
    return {
      clubs: this.clubs,
      selectClub: this.selectClub,
      selectDevice: this.selectDevice,
      onTimeChange: this.onTimeChange,
      periods: this.periods,
      selectPeriod: this.selectPeriod,
    };
  },
  methods: {
    async selectClub(club) {
      this.isLoading = true;

      this.selectedClub = club;
      this.storeSelectedClubId(club.id);
      this.$refs.sidebar.setClubDropDownText(club.name);
      await this.$refs.sidebar.loadZones(club.id);
      await this.reloadMap();

      this.isLoading = false;
    },
    selectDevice(device) {
      if (this.displayReserve) return;

      if (!device.id) return; // dummy left panel device

      if (!device.is_available) return;

      if (!this.isLoggedIn) {
        this.displayAuth = true;
        return;
      }

      if (
        this.selectedDevices.length ==
          this.selectedClub.reserve_max_devices_for_user &&
        !device.is_selected
      ) {
        this.selectedDevices = this.selectedDevices.filter(
          (x) => x.id !== device.id
        );
        return;
      }

      device.is_selected = !device.is_selected;

      if (device.is_selected) {
        this.selectedDevices.push(device);
      } else {
        this.selectedDevices = this.selectedDevices.filter(
          (x) => x.id !== device.id
        );
      }
    },
    async loadData() {
      this.isLoading = true;

      await this.$store.dispatch('club/loadClubs');

      await this.loadProfile();

      await this.$store.dispatch('reservations/loadPeriods');
      this.selectedPeriod = this.periods[0];
      this.$refs.sidebar.setPeriodDropDownText(this.selectedPeriod.name);

      if (this.clubs.length > 0) {
        const storedSelectedClubId = this.loadSelectedClubId();

        let clubToSelect = this.clubs.find((x) => x.id == storedSelectedClubId);

        if (clubToSelect == null) clubToSelect = this.clubs[0];

        this.selectClub(clubToSelect);
      }

      this.isLoading = false;
    },
    async loadProfile() {
      if (this.isLoggedIn) {
        try {
          await this.$store.dispatch('auth/getProfile');
        } catch (err) {
          if (err === '401') {
            this.$store.dispatch('auth/removeToken');
          }
        }
      }
    },
    onTimeChange(hour, minute) {
      if (this.hour === hour && this.minute === minute) return;

      this.hour = hour;
      this.minute = minute;

      console.log(`onTimeChange: ${hour}:${minute}`);

      this.reloadMap();
    },
    onDateChange(date) {
      if (this.date === date) return;

      this.date = date;

      console.log(`onDateChange: ${date}`);

      this.reloadMap();
    },
    async reloadMap() {
      if (this.selectedClub === null) return;
      if (this.date === null) return;
      if (this.selectedPeriod === null) return;

      this.isLoading = true;

      let dateFrom = this.dateFrom;

      const dateTo = new Date(
        dateFrom.getTime() + this.selectedPeriod.minutes * 60000
      );

      await this.$refs.deviceMap.loadMap(
        this.selectedClub.id,
        dateFrom,
        dateTo
      );

      this.selectedDevices = [];

      this.isLoading = false;
    },
    selectPeriod(period) {
      this.selectedPeriod = period;
      this.reloadMap();
    },
    storeSelectedClubId(clubId) {
      localStorage.setItem('selected_club_id', clubId);
    },
    loadSelectedClubId() {
      return localStorage.getItem('selected_club_id');
    },
    restorePasswordHandler() {
      this.displayAuth = false;
      this.displayRestore = true;
    },
    onReserveDialogClosed() {
      this.displayReserve = false;

      if (this.$refs.reserveDialog.createdReserve) {
        this.selectedDevices.forEach((device) => {
          device.is_selected = false;
        });
        this.selectedDevices = [];
        this.reloadMap();
      } else {
        this.selectedDevices.forEach((device) => {
          device.is_selected = true;
        });
      }
    },
    onMakeReserveButtonClicked() {
      this.selectedDevices.forEach((device) => {
        device.is_selected = false;
      });

      this.displayReserve = true;
    },
  },
};
</script>

<style scoped>
* {
  box-sizing: border-box;
}

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#loaderimg {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}

#makeReserveButton {
  font-weight: bold;
  letter-spacing: 0.5px;
  margin: 0 auto;
  display: block;
}

h3 {
  align-self: flex-start;
  color: white;
  margin-left: 50px;
  margin-top: 20px;
}

.row::after {
  content: '';
  clear: both;
  display: table;
}

[class*='col-'] {
  float: left;
  padding: 0px;
}

/* For mobile phones: */
[class*='col-'] {
  width: 100%;
}

@media only screen and (min-width: 768px) {
  /* For desktop: */
  .col-1 {
    width: 8.33%;
  }
  .col-2 {
    width: 16.66%;
  }
  .col-3 {
    width: 25%;
  }
  .col-4 {
    width: 33.33%;
  }
  .col-5 {
    width: 41.66%;
  }
  .col-6 {
    width: 50%;
  }
  .col-7 {
    width: 58.33%;
  }
  .col-8 {
    width: 66.66%;
  }
  .col-9 {
    width: 75%;
  }
  .col-10 {
    width: 83.33%;
  }
  .col-11 {
    width: 91.66%;
  }
  .col-12 {
    width: 100%;
  }

  #main_container {
    flex-grow: 1;
  }

  #sidebar {
    height: 100%;
    border-right: 1px solid grey;
  }

  #loaderimg {
    padding: 0;
    position: fixed;
    left: 55%;
    top: 40%;
    margin-right: 0%;
    transform: translate(0, 0);
  }

  #makeReserveButton {
    position: fixed;
    left: 85%;
    top: 90%;
  }
}
</style>

<style>
button {
  font: inherit;
  cursor: pointer;
  border: 1px solid #75b4ff;
  font-size: 0.8em;
  font-weight: normal;
  background-color: transparent;
  color: white;
  padding: 10px 40px;
}

button:hover {
  background-color: #75b4ff;
}
</style>
