<template>
  <div id="timeinput">
    <input
      type="text"
      inputmode="numeric"
      id="hourInput"
      size="2"
      :value="hour"
      ref="hour"
      @focus="hourFocused"
      @keypress="checkNan($event)"
      @input="checkHour($event)"
      @blur="blured($event)"
    />
    <label>:</label>
    <input
      type="text"
      inputmode="numeric"
      id="minuteInput"
      size="2"
      :value="minute"
      ref="minute"
      @focus="minFocused"
      @keypress="checkNan($event)"
      @input="checkMinute($event)"
      @blur="blured($event)"
    />
    <div id="buttonsContainer">
      <button @click="upClick"><i class="arrow up"></i></button>
      <button @click="downClick"><i class="arrow down"></i></button>
    </div>
  </div>
</template>

<script>
export default {
  emits: ['time-changed'],
  data() {
    return {
      hour: '',
      minute: '',
      hourLastFocused: true,
      timeout: null,
      debounce: this.createDebounce(),
    };
  },
  mounted() {
    var date = new Date();

    const hour = date.getHours();
    const min = date.getMinutes();

    this.hour = String(hour).padStart(2, '0');
    this.minute = String(min).padStart(2, '0');
    this.fireTimeChangedImmediate();
  },
  methods: {
    checkNan(event) {
      const num = event.key;

      if (isNaN(num)) {
        console.log(`Input ${num} is NaN, preventing`);
        event.preventDefault();
      }
    },
    checkHour(event) {
      let newHour = event.target.value;
      newHour = newHour.slice(-2);
      console.log(`new hour is ${newHour}`);

      if (newHour >= 0 && newHour <= 23) {
        this.hour = newHour;
        event.target.value = newHour;
      } else {
        console.log('Input is out of range, preventing');
        this.hour = '00';
        event.target.value = '00';
      }

      this.fireTimeChanged();
    },
    checkMinute(event) {
      let newMinute = event.target.value;
      newMinute = newMinute.slice(-2);
      console.log(`new minute is ${newMinute}`);

      if (newMinute >= 0 && newMinute <= 59) {
        this.minute = newMinute;
        event.target.value = newMinute;
      } else {
        console.log('Input is out of range, preventing');
        this.newMinute = '00';
        event.target.value = '00';
      }

      this.fireTimeChanged();
    },
    hourFocused() {
      this.hourLastFocused = true;
    },
    minFocused() {
      this.hourLastFocused = false;
    },
    focusLastFocused() {
      if (this.hourLastFocused) {
        this.$refs.hour.focus();
      } else {
        this.$refs.minute.focus();
      }
    },
    upClick() {
      this.focusLastFocused();

      if (this.hourLastFocused) {
        if (this.hour < 23) {
          this.hour++;
          this.hour = String(this.hour).padStart(2, '0');
          this.fireTimeChanged();
        }
      } else {
        if (this.minute < 59) {
          this.minute++;
          this.minute = String(this.minute).padStart(2, '0');
          this.fireTimeChanged();
        }
      }
    },
    downClick() {
      this.focusLastFocused();

      if (this.hourLastFocused) {
        if (this.hour > 0) {
          this.hour--;
          this.hour = String(this.hour).padStart(2, '0');
          this.fireTimeChanged();
        }
      } else {
        if (this.minute > 0) {
          this.minute--;
          this.minute = String(this.minute).padStart(2, '0');
          this.fireTimeChanged();
        }
      }
    },
    blured(event) {
      const input = event.target;
      const inputNum = +input.value;

      const padded = String(inputNum).padStart(2, '0');

      if (input.id === 'hourInput') {
        this.hour = padded;
      } else {
        this.minute = padded;
      }
    },
    createDebounce() {
      return (fnc, delayMs) => {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          fnc();
        }, delayMs || 500);
      };
    },
    fireTimeChanged() {
      this.debounce(
        () => this.$emit('time-changed', this.hour, this.minute),
        2000
      );
    },
    fireTimeChangedImmediate() {
      this.$emit('time-changed', this.hour, this.minute);
    },
  },
};
</script>

<style scoped>
#timeinput {
  width: fit-content;
  display: flex;
  margin-left: 16px;
  margin-top: 16px;
  border: 1px solid grey;
  padding: 5px 0;
  align-items: center;
  justify-content: center;
}

#timeinput label {
  color: white;
}

input:focus {
  background-color: #366094;
}

#hourInput,
#minuteInput {
  margin: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  display: block;
  width: 25px;
  caret-color: transparent;
}

.arrow {
  border: solid white;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 2px;
}

.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

button {
  display: block;
  padding: 0 5px;
  border: 0;
}
</style>
