<template>
  <div id="maindiv" @click="close"></div>
  <dialog open>
    <img class="close" src="./../../assets/close.png" @click="close" />
    <header>
      <div
        :class="selectedComponent === 'SignInPage' ? 'active' : ''"
        @click="setSelectedComponent('SignInPage')"
      >
        Вход
      </div>
      <div
        :class="selectedComponent === 'RegistrationPage' ? 'active' : ''"
        @click="setSelectedComponent('RegistrationPage')"
      >
        Регистрация
      </div>
    </header>
    <section>
      <component :is="selectedComponent" @authenticated="close"></component>
      <h5 v-if="selectedComponent === 'SignInPage'">
        <a @click="restore">Забыли логин/пароль?</a>
      </h5>
    </section>
  </dialog>
</template>

<script>
import RegistrationPage from './RegistrationPage.vue';
import SignInPage from './SignInPage.vue';

export default {
  emits: ['close', 'restorePassword'],
  components: {
    RegistrationPage,
    SignInPage,
  },
  props: {
    title: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      selectedComponent: 'SignInPage',
    };
  },
  methods: {
    setSelectedComponent(cmp) {
      this.selectedComponent = cmp;
    },
    close() {
      this.$emit('close');
    },
    restore() {
      this.$emit('restorePassword');
    },
  },
};
</script>

<style>
input {
  padding: 7px 5px;
  background-color: transparent;
  border: 1px solid #555;
  color: white;
}
</style>

<style scoped>
#maindiv {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 10;
}
div {
  display: block;
  width: 100%;
  text-align: center;
  cursor: pointer;
  padding: 1rem;
}
div.active {
  border-bottom: 2px solid #75b4ff;
}
dialog {
  position: fixed;
  top: 30vh;
  left: 10%;
  width: 80%;
  z-index: 100;
  border: none;
  padding: 0;
  margin: 0;
  overflow: hidden;
  background-color: #232529;
}
header {
  color: white;
  width: 100%;
  display: flex;
  border-bottom: 1px solid grey;
}
header h2 {
  margin: 0;
}
section {
  padding: 1rem;
}
img.close {
  width: 16px;
  height: 16px;
  cursor: pointer;
  padding: 16px;
  float: right;
}
a {
  text-decoration: underline;
  color: white;
  display: inline-block;
  border: 1px solid transparent;
  cursor: pointer;
}
@media (min-width: 768px) {
  dialog {
    left: calc(50% - 20rem);
    width: 40rem;
  }
}
</style>
