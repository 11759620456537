import { createStore } from 'vuex';

import reservationsModule from './modules/reservations/index.js';
import mapModule from './modules/map/index.js';
import clubModule from './modules/club/index.js';
import authModule from './modules/auth/index.js';
import userModule from './modules/user/index.js';

const store = createStore({
  modules: {
    reservations: reservationsModule,
    map: mapModule,
    club: clubModule,
    auth: authModule,
    user: userModule,
  },
});

export default store;

export function getUrl(relativeUrl) {
  let baseUrl = process.env.VUE_APP_BASEURL;
  return `${baseUrl}/${relativeUrl}`;
}

export function toISOLocal(date) {
  return new Date(
    date.getTime() - date.getTimezoneOffset() * 60000
  ).toISOString();
}
