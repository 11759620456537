<template>
  <div id="main_div">
    <div id="header_div">
      <div id="spans_div">
        <span>{{ date.toLocaleString() }}</span>
        <span>{{ period.name }} </span>
        <div id="deposit_sum">
          <span
            >Стоимость аренды: &nbsp; {{ devices[0].reserve_price }} руб</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    devices: null,
    period: null,
    date: null,
  },
};
</script>

<style scoped>
span {
  color: white;
  margin: 0 10px;
  float: left;
}

#header_div {
  display: flex;
}

#main_div {
  padding: 10px;
  margin: 15px;
  border: 1px solid grey;
}

#spans_div {
  padding: 0 10px;
}

#deposit_sum {
  margin-top: 25px;
}
</style>
