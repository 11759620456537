<template>
  <div class="root">
    <h5><a @click="goBack">« Назад</a></h5>
    <h3>Мои резервы</h3>
    <v-table :reservations="reservations"></v-table>
  </div>
</template>

<script>
import vTable from './../../components/table/v-table.vue';

export default {
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    reservations() {
      return this.$store.getters['reservations/reservations'];
    },
    hasHistory() {
      return window.history.length > 2;
    },
  },
  components: {
    vTable,
  },
  created() {
    this.loadMyReservations();
  },
  methods: {
    async loadMyReservations() {
      this.isLoading = true;

      try {
        await this.$store.dispatch('reservations/loadMy');
      } catch (err) {
        if (err === '401') {
          this.$store.dispatch('auth/removeToken');
          this.$router.replace('/office');
        }
      }

      this.isLoading = false;
    },
    goBack() {
      if (this.hasHistory) this.$router.go(-1);
      else this.$router.push('/');
    },
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
  color: white;
  display: inline-block;
  border: 1px solid transparent;
  cursor: pointer;
}

.root {
  width: 90%;
  margin: 30px auto;
}

h3 {
  color: white;
}

@media only screen and (min-width: 1200px) {
  .root {
    width: 1200px;
    margin: 150px auto;
  }
}
</style>
