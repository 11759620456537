<template>
  <li
    @click="onClick"
    :class="device.is_available ? '' : 'unavailable'"
    v-bind:style="{
      backgroundColor: device.is_selected ? '#75b4ff' : 'transparent',
    }"
  >
    <div class="container">
      <pc-svg v-if="device.type === 'pc'" id="svg" class="circle" />
      <console-svg v-if="device.type === 'console'" id="svg" class="circle" />
    </div>
    <figcaption>{{ device.number }}</figcaption>
    <div class="zone" :style="{ 'background-color': device.zone_color }" />
  </li>
</template>

<script>
import PcSvg from './PcSvg.vue';
import ConsoleSvg from './ConsoleSvg.vue';

export default {
  components: {
    PcSvg,
    ConsoleSvg,
  },
  inject: ['selectDevice'],
  props: {
    device: {},
  },
  methods: {
    onClick() {
      this.selectDevice(this.device);
    },
  },
};
</script>

<style scoped>
li {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

figcaption {
  text-align: center;
  margin-top: 1px;
  font-weight: normal;
  color: white;
  font-size: 11px;
}

#svg {
  width: 14px;
  height: 14px;
}

.container {
  border-radius: 50%;
  width: 14px;
  height: 14px;
  background-color: white;
  padding: 2px;
}

.zone {
  width: 16px;
  height: 6px;
}

.unavailable {
  cursor: not-allowed;
  opacity: 0.4;
}

@media only screen and (min-width: 768px) {
  /* For desktop: */
  figcaption {
    margin-top: 2px;
    font-size: 16px;
  }
  #svg {
    width: 20px;
    height: 20px;
  }
  .container {
    width: 20px;
    height: 20px;
    padding: 4px;
  }
  .zone {
    width: 32px;
    height: 8px;
  }
}
</style>
