<template>
  <div id="maindiv" @click="close"></div>
  <dialog open>
    <img class="close" src="./../../assets/close.png" @click="close" />
    <header>
      <label>Восстановление пароля</label>
    </header>
    <section>
      <form @submit.prevent="submitForm">
        <label v-if="!codeStage && !successStage"
          >Введите email, указанный при регистрации</label
        >
        <input
          v-if="!codeStage && !successStage"
          type="email"
          v-model="email"
        />
        <label v-if="codeStage && !successStage"
          >Введите код проверки, отправленный на email, и новый пароль</label
        >
        <input
          v-if="codeStage && !successStage"
          v-model="code"
          placeholder="Код проверки"
        />
        <PasswordEyed
          v-if="codeStage && !successStage"
          v-model="newPassword"
          class="passwordEyed"
          type="password"
          placeholder="Новый пароль"
        />
        <p class="error" v-if="error">{{ error }}</p>
        <p v-if="!formIsValid" class="error">Пожалуйста, заполните все поля</p>
        <p class="success" v-if="successStage">{{ this.successText }}</p>
        <button v-if="!successStage">Дальше</button>
      </form>
    </section>
  </dialog>
</template>

<script>
import PasswordEyed from './../../components/PasswordEyed.vue';

export default {
  emits: ['close'],
  components: {
    PasswordEyed,
  },
  data() {
    return {
      email: '',
      code: '',
      newPassword: '',
      codeStage: false,
      successStage: false,
      formIsValid: true,
      isLoading: false,
      error: null,
      successText: '',
    };
  },
  methods: {
    async submitForm() {
      this.error = null;
      this.formIsValid = true;

      if (this.codeStage) await this.verifyCode();
      else await this.requestEmail();
    },
    async requestEmail() {
      if (this.email === '') {
        this.formIsValid = false;
        return;
      }

      try {
        await this.$store.dispatch('auth/restorePasswordSendEmail', {
          email: this.email,
        });
      } catch (err) {
        this.error = err.message || 'Failed to fetch, try later.';
        return;
      }

      this.codeStage = true;
    },
    async verifyCode() {
      if (this.email === '' || this.code === '' || this.newPassword === '') {
        this.formIsValid = false;
        return;
      }

      let response = null;

      try {
        response = await this.$store.dispatch('auth/restorePassword', {
          email: this.email,
          code: this.code,
          newPassword: this.newPassword,
        });
      } catch (err) {
        this.error = err.message || 'Failed to fetch, try later.';
        return;
      }

      this.successStage = true;
      this.successText = response.result;
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style scoped>
#maindiv {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 10;
}

dialog {
  position: fixed;
  top: 30vh;
  left: 10%;
  width: 80%;
  z-index: 100;
  border: none;
  padding: 0;
  margin: 0;
  overflow: hidden;
  background-color: #232529;
}

header {
  color: white;
  width: 100%;
  display: flex;
}

header h2 {
  margin: 0;
}

section {
  padding: 1rem;
}

img.close {
  width: 16px;
  height: 16px;
  cursor: pointer;
  padding: 16px;
  float: right;
}

@media (min-width: 768px) {
  dialog {
    left: calc(50% - 20rem);
    width: 40rem;
  }
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
}
p,
label {
  color: white;
}
p.error {
  color: brown;
}
label {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 20px;
  margin-left: 20px;
}
button {
  margin-top: 20px;
  width: 150px;
}
.passwordEyed,
input {
  margin-top: 10px;
  margin-bottom: 10px;
}
p.success {
  color: lightgreen;
}
</style>
