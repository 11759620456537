<template>
  <div class="sidenav">
    <h3>Резерв ПК</h3>
    <span class="title">Клуб</span>
    <dropDown
      class="dp"
      ref="dp"
      :items="clubs"
      @item-selected="selectClub"
      title="Выберите клуб"
    />
    <div id="date-input-div">
      <input
        id="date-input"
        ref="dateinput"
        type="date"
        placeholder="dd-mm-yyyy"
        @change="dateChange($event)"
      />
    </div>
    <div id="timePeriodDiv">
      <timeInput @time-changed="onTimeChange" />
      <DropDown
        class="dp"
        ref="perioddp"
        id="periodDp"
        :items="periods"
        @item-selected="selectPeriod"
      />
    </div>
    <h4>Зоны клуба:</h4>
    <ul>
      <li v-for="zone in zones" :key="zone.id">
        <div class="zone" :style="{ 'background-color': zone.color }" />
        <label>{{ zone.name }}</label>
      </li>
    </ul>
    <ul class="deviceLegend">
      <li>
        <div>
          <device class="device" :device="device"></device>
          <label>— свободно</label>
        </div>
      </li>
      <li>
        <div>
          <device class="device" :device="unavailableDevice"></device>
          <label>— зарезервировано</label>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import Device from '../../components/map/DeviceItem.vue';
import TimeInput from '../../components/TimeInput.vue';
import DropDown from './../../components/dropdown/DropDown.vue';
import { toISOLocal } from '../../store/index.js';
export default {
  inject: ['clubs', 'selectClub', 'periods', 'selectPeriod', 'onTimeChange'],
  emits: ['dateChanged'],
  data() {
    return {
      device: {
        is_available: true,
        type: 'pc',
      },
      unavailableDevice: {
        is_available: false,
        type: 'pc',
      },
    };
  },
  computed: {
    zones() {
      return this.$store.getters['club/zones'];
    },
  },
  methods: {
    async loadZones(clubId) {
      await this.$store.dispatch('club/loadZones', clubId);
    },
    setClubDropDownText(text) {
      this.$refs.dp.setText(text);
    },
    setPeriodDropDownText(text) {
      this.$refs.perioddp.setText(text);
    },
    dateChange(event) {
      const dateInput = event.target;
      this.$emit('dateChanged', dateInput.value);
    },
  },
  components: {
    DropDown,
    Device,
    TimeInput,
  },
  mounted() {
    const now = new Date();
    const nextMonth = new Date(
      now.getFullYear(),
      now.getMonth() + 1,
      now.getDate()
    );
    this.$refs.dateinput.max = nextMonth.toLocaleDateString('en-ca');
    this.$refs.dateinput.min = now.toLocaleDateString('en-ca');

    var currentDate = toISOLocal(now).substring(0, 10);
    this.$refs.dateinput.value = currentDate;
    this.$emit('dateChanged', currentDate);
  },
};
</script>

<style scoped>
/* The sidebar menu */
.sidenav {
  z-index: -1;
  overflow-x: hidden; /* Disable horizontal scroll */
  padding-top: 20px;
}

h3,
h4 {
  color: white;
  padding: 6px 0px 6px 16px;
  font-weight: 100;
}

span.title {
  color: white;
  font-weight: 100;
  padding: 5px 16px 10px;
  display: inherit;
}

.dp {
  padding: 0 16px;
  border-radius: 0;
}

#timePeriodDiv {
  display: flex;
}

#periodDp {
  margin-top: 16px;
  flex-grow: 1;
}

#date-input-div {
  display: flex;
}

#date-input {
  padding: 10px 8px;
  display: block;
  margin-left: 16px;
  margin-right: 16px;
  margin-top: 16px;
  flex: 1;
  color-scheme: dark;
}

li {
  color: white;
}

ul {
  list-style-type: none;
  padding: 0 16px;
}

label {
  padding: 0 8px;
}

div.zone {
  width: 10px;
  height: 10px;
  display: inline-block;
}

.deviceLegend {
  margin-top: 40px;
  margin-left: 5px;
  list-style-type: none;
}

.deviceLegend li {
  display: flex;
  margin-bottom: 2px;
  margin-top: 2px;
}

.deviceLegend div {
  align-items: center;
  display: flex;
}

.deviceLegend label {
  color: white;
  margin-left: 0px;
  margin-top: -10px;
}

.device {
  cursor: initial;
}
</style>
