import { getUrl } from '../../index.js';
import { toISOLocal } from '../../index.js';

export default {
  async create(context, payload) {
    const response = await fetch(getUrl('reservationV2'), {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        token: context.rootState.auth.token,
        date: toISOLocal(payload.date),
        club_id: payload.clubId,
        devices_ids: payload.deviceIds,
        duration_min: payload.durationMin,
        deposit: payload.deposit,
      }),
    });

    const responseData = await response.json();

    if (!response.ok) {
      console.log(responseData);
      const error = new Error(
        responseData.error || 'Failed to create reservation.'
      );
      throw error;
    }

    return responseData;
  },
  async loadMy(context) {
    let formData = new FormData();
    formData.append('token', context.rootState.auth.token);

    const response = await fetch(getUrl('reserves'), {
      method: 'POST',
      body: formData,
    });
    const responseData = await response.json();

    if (!response.ok) {
      console.log(responseData);

      if (response.status == 401) {
        throw '401';
      }

      const error = new Error(
        responseData.error || 'Failed to load reservations.'
      );
      throw error;
    }

    const reservs = [];

    for (const key in responseData) {
      const reserv = {
        id: responseData[key].id,
        datetime: new Date(responseData[key].from_datetime),
        deposit: responseData[key].amount,
        duration: responseData[key].duration,
        club: responseData[key].club_name,
        device_number: responseData[key].pc_number,
        code: responseData[key].code,
        zone: responseData[key].zone_name,
      };

      reservs.push(reserv);
    }

    context.commit('setMyReservations', reservs);
  },
  async cancel(context, id) {
    const data = { token: context.rootState.auth.token, id: id };

    const response = await fetch(getUrl('reservation'), {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    const responseData = await response.json();

    if (!response.ok) {
      console.log(responseData);
      const error = new Error(
        responseData.error || 'Failed to create reservation.'
      );
      throw error;
    }
  },
  async loadPeriods(context) {
    let formData = new FormData();
    formData.append('token', context.rootState.auth.token);

    const response = await fetch(getUrl('reserve_periods'));
    const responseData = await response.json();

    if (!response.ok) {
      console.log(responseData);

      const error = new Error(
        responseData.error || 'Failed to load reservation periods.'
      );
      throw error;
    }

    const periods = [];

    for (const key in responseData) {
      const period = {
        id: responseData[key].id,
        name: responseData[key].name,
        minutes: responseData[key].minutes,
      };

      periods.push(period);
    }

    context.commit('setReservationPeriods', periods);
  },
};
