<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 471 471"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    enable-background="new 0 0 471 471"
  >
    <g>
      <path
        d="m443.5,86.676h-416c-2.761,0-5,2.239-5,5v253.493c0,2.761 2.239,5 5,5h416c2.762,0 5-2.239 5-5v-253.493c0-2.762-2.238-5-5-5zm-5,253.493h-406v-243.493h406v243.493z"
      />
      <path
        d="m449.055,64.176h-427.109c-12.101,0-21.946,9.845-21.946,21.945v264.602c0,12.101 9.845,21.945 21.946,21.945h148.343l-7.91,19.156h-31.772c-4.142,0-7.5,3.358-7.5,7.5s3.358,7.5 7.5,7.5h209.79c4.143,0 7.5-3.358 7.5-7.5s-3.357-7.5-7.5-7.5h-31.772l-7.911-19.156h148.343c12.101,0 21.945-9.845 21.945-21.945v-264.602c-0.002-12.1-9.846-21.945-21.947-21.945zm-156.661,327.649h-113.788l7.911-19.156h97.967l7.91,19.156zm163.606-41.101c0,3.83-3.115,6.945-6.945,6.945h-427.109c-3.83,0-6.946-3.116-6.946-6.945v-264.603c0-3.83 3.116-6.946 6.946-6.946h427.109c3.83,0 6.945,3.116 6.945,6.946v264.603z"
      />
    </g>
  </svg>
</template>
