export default {
  setMyReservations(state, payload) {
    state.reservations.splice(0, state.reservations.length);
    payload.forEach((reservation) => {
      state.reservations.push(reservation);
    });
  },
  setReservationPeriods(state, payload) {
    state.periods.splice(0, state.periods.length);
    payload.forEach((period) => {
      state.periods.push(period);
    });
  },
};
